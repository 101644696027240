import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { LandingPageComponent } from './components/landing-page/landing-page.component';



const routes: Routes = [
    {path: '', component: LandingPageComponent},
    {path: 'landing-page', component: LandingPageComponent},
    
];

@NgModule({
    imports: [BrowserModule,RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }