<nav class="navbar navbar-expand-lg navbar-light navbar-light-four navbar.scrolled-up sticky-top">
    <!-- Navbar Brand -->
    <a class="navbar-brand" routerLink="/home-five">
        <img class="nav-logo" src="assets/img/logo.png" alt="Logo">
    </a>

    <!-- Navbar Collapse Wrapper -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!-- Login Button Aligned to the Right -->
        <button class="btn login-button ms-auto" type="button" (click)="navigateToLogin()">Login</button>
    </div>

    <!-- Toggler Button for Mobile View -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
</nav>



<section class="ptb-80 landing-bg pb-5">
<div class="row d-flex align-items-center pb-5 p-2 m-1">
    <div class="col-md-2 col-12" align="center">
        <img class="img-fluid slider-image element-from-right"  data-aos="custom-slide-from-right" src="assets/img/sjacs-imgs/banner-main1.png" alt="Shape">
    </div>
    
    <div class="col-md-5 col-12 p-5">
        <div class="header-container  d-flex align-items-center element-from-right">
            <span class="welcome-text" data-aos="custom-slide-from-right">Welcome To SJ Aircon Services</span>
          </div>
          
        <h1 class="main-head element-from-right center-head" data-aos="custom-slide-from-right">Empowering Efficient Service, Every Time
            <!-- <span class="hard-money">Hard Money Loans</span> -->
        </h1>
        <p class="subhead element-from-right sub-para" style="line-height: 1.5;" data-aos="custom-slide-from-right">SJ Aircon Services offers a smart, efficient solution for managing jobs, streamlining employee workflows, and optimizing service management.</p>

        
    </div>
    <div class="col-md-5 col-12 element-from-left" align="center"data-aos="custom-slide-from-left" >
        <img class="img-fluid slider-image" src="assets/img/sjacs-imgs/banner-main.png" alt="Shape">
    </div>
</div>
</section>

<!-- <section class="section2-bg"> -->
 
<section class="five-loan-area">
    <div class="container-row1 mb-5">
        
    <div>
        <div class="container element-from-bottom"  data-aos="fade-up">
            <div class="row justify-content-center">
                <div class="col-md-4 px-4">
                    <div class="text-center">
                        <!-- <i class="fa-solid fa-stopwatch icons"></i> -->
                        <img class="top-svg" src="assets/img/svg/Streamlined Communication.svg"/>
                        <h3 class="subhead section2-head">Streamlined Communication</h3>
                        <p class="subhead sub-para section2-body">SJ Aircon Services enables efficient, real-time communication that connects teams seamlessly and ensures smooth service delivery.</p>
                        
                    </div>
                </div>
                <div class="col-md-4 px-4">
                    <div class="text-center">
                        <!-- <i class="fa-solid fa-stopwatch icons"></i> -->
                        <img class="top-svg" src="assets/img/svg/Better Task Visibility.svg"/>
                        <h3 class=" subhead section2-head">Better Task Visibility</h3>
                        <p class="subhead section2-body sub-para">SJ Aircon Services providing teams complete visibility over daily operations. managers and employees alike can easily track and prioritize work.</p>
                        
                    </div>
                </div>
                <div class="col-md-4 px-4">
                    <div class="text-center">
                        <!-- <i class="fa-solid fa-stopwatch icons"></i> -->
                        <img class="top-svg" src="assets/img/svg/Greater Productivity.svg"/>
                        <h3 class="subhead section2-head">Greater Productivity</h3>
                        <p class="subhead section2-body sub-para">SJ Aircon Services empowers teams to work smarter and accomplish more with less effort. By streamlining job assignments, improving task visibility, and facilitating clear communication</p>
                        
                    </div>
                </div>
                
               
                
            </div>
        </div>
    </div>    
<div class="row d-flex align-items-center justify-content-center total-space pb-5">
            
            <div class="col-md-4 col-12" align="center">
                <img class="img-fluid slider-image element-from-right slider-image1" src="assets/img/sjacs-imgs/banner-main2.png" alt="Shape" data-aos="custom-slide-from-right">
            </div>
            <div class="col-md-6 col-12 p-5">
                <h1 class="subhead section2-head element-from-left" data-aos="custom-slide-from-left">Streamline and optimize service management for Aircon Service Companies</h1>
                <p class="subhead section2-body element-from-left" data-aos="custom-slide-from-left">SJ Aircon Services offers an innovative platform to streamline and optimize service management, making day-to-day operations more efficient and effective.</p>
                <p class="subhead section2-body element-from-left" data-aos="custom-slide-from-left">Our solution empowers businesses to assign, track, and complete jobs seamlessly, transforming the way teams handle their daily responsibilities. With SJ Aircon Services, managers can easily create jobs based on specific requirements, allocate tasks to the right team members, and monitor progress from start to finish.</p>
                <p class="subhead section2-body element-from-left" data-aos="custom-slide-from-left">With SJ Aircon Services, you can achieve seamless job management and superior service delivery, ensuring that every client experience is optimized, and every task is completed efficiently.</p>
            </div>

            
        </div>
        
    </div>
</section>

<div class="fun-fact-style-area pb-100 pt-5 section-green-bg">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="fun-fact-style-card">
                            <h3>15</h3>
                            <p>Years Of Experience</p>
                        </div>
                    </div>

                   
                      

                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="fun-fact-style-card">
                            <h3>6K</h3>
                            <p>Job's completed so far</p>
                        </div>
                    </div>

                    
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="one-section-title mt-5">
                    <span class="primary-color-text"># SJ AIRCON SERVICES STATISTICS
                    </span>
                    <h3 class="section2-head">There is some fact that you know</h3>
                    <p class="section2-body">Our team deliveried / completed these many goals so far</p>
                </div>

            </div>
        </div>
    </div>

</div>


<div class="three-review-area four-review-area section2-bg pb-100 product-section4-bg">
    <div class="container">
        <div class="one-section-title two-section-title">
            <span class="sub-title"># CLIENT'S LOVE</span>
            <h2 class="section2-head">Our Clients Love</h2>
        </div>

        <div class="three-review-slider owl-theme owl-carousel">
            <div class="review-item">
                <i class='bx bxs-quote-left'></i>
                <h2>Working with SJ Aircon Services has been a game-changer for our team. The platform's intuitive job management and streamlined communication tools have completely transformed how we operate day-to-day. Assigning and tracking jobs is now seamless, and our team has clear visibility into their tasks, which keeps everyone aligned and productive.</h2>
                <div class="review-bottom">
                    <!-- <img src="assets/img/home-three/review.png" alt="Review"> -->
                    <h3>Sreekanth Papenani</h3>
                    <span>RS Air Condtioners Hyderabad Pvt Ltd.</span>
                </div>
            </div>

            <div class="review-item">
                <i class='bx bxs-quote-left'></i>
                <h2>Since adopting SJ Aircon Services, our workflow has become remarkably organized and efficient. The platform allows us to assign, monitor, and complete tasks with such ease, and the real-time updates keep everyone informed and on the same page. Our team has found the job creation and filtering features especially useful, as they ensure that every detail is accounted for, and nothing slips through the cracks.</h2>
                <div class="review-bottom">
                    <!-- <img src="assets/img/home-three/review.png" alt="Review"> -->
                    <h3>Ajay Kumar</h3>
                    <span>AC Service Dealer</span>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="wave-container" style="position: relative;">
    <svg id="wave" style="transform:rotate(180deg); transition: 0.3s" viewBox="0 0 1440 160" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                <stop stop-color="rgba(243, 106, 62, 1)" offset="0%"></stop>
                <stop stop-color="rgba(255, 179, 11, 1)" offset="100%"></stop>
            </linearGradient>
        </defs>
    </svg>
    
    
    <div id="contact" class="let-contact-area-with-bg-image ptb-70">
        <div class="container">
          <div class="let-contact-form pb-3">
            <h4 class="thin-font pt-5">Contact With Us</h4>
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
              <div class="row m-0">
                <div class="col-sm-6 col-lg-6">
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" formControlName="name" class="form-control" placeholder="Sriram">
                  </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" formControlName="email" class="form-control" placeholder="sriram@gmail.com">
                  </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input type="text" formControlName="phone_number" class="form-control" placeholder="+91 9985402433">
                  </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <div class="form-group">
                    <label>Subject</label>
                    <input type="text" formControlName="msg_subject" class="form-control" placeholder="your subject">
                  </div>
                </div>
                <div class="col-md-12 col-lg-12">
                  <div class="form-group">
                    <label>Your Message</label>
                    <textarea formControlName="message" class="form-control" rows="8" placeholder="write your message"></textarea>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12">
                  <div class="let-contact-btn">
                    <button type="submit" class="main-default-btn">Send Message</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      
</div>

<footer class="footer-section-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-4">
                <div class="single-footer-widget">
                    <a href="#">
                        <img src="assets/img/logo.png" alt="Logo">
                    </a>
                    <p class="subhead-logo mt-3">Empowering Efficient Service, Every Time</p>
                    
                    <!-- <div class="row">
                        <div class="col d-flex">
                            <i class='bx bxl-facebook social-icon'></i>
                            <i class='bx bxl-twitter social-icon'></i>
                            <i class='bx bxl-linkedin social-icon'></i>
                            <i class='bx bxl-instagram social-icon'></i>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="single-footer-widget">
                    <div class="footer-logo">
                        <a href="#">
                            <img src="assets/img/Velox logo.svg" alt="Logo">
                        </a>
                        <p class="subhead">Streamlining Real Estate Financing with Hard Money Loans</p>
                        <ul class="footer-social">
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>

            <div class="col-12 col-lg-4">
                <div class="single-footer-widget ps-3">
                    <h3>Download Our App</h3>
                    
                    <ul class="quick-link">
                        <li></li>
                            <li><a href="https://play.google.com/store/apps/details?id=in.sjsolutions.workforce"><img src="../../../assets/img/sjacs-imgs/play store.png" style="height: 70px;"></a></li>
                            <li><a href="https://apps.apple.com/us/app/sj-aircon-services-acs/id6472700501"><img src="../../../assets/img/sjacs-imgs/app store.png" style="height: 70px;"></a></li> 
                        </ul>
                </div>
            </div>


            <div class="col-12 col-lg-4">
                <div class="single-footer-widget ps-3">
                    <h3>Reach Us</h3>
                    
                    <ul class="quick-link">
                            <li><a routerLink="/">sriram&#64;sjsolutions.in</a></li>
                            <li><a routerLink="/">+91 9985402433</a></li>
                            
                        </ul>
                </div>
            </div>


            

            

        </div>

        <hr class="opacity-xs mt-8 mb-4">

        <!-- <div class="copyright-style-area">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="copyright-item">
                        <p>© 2024 Velox Lending, Inc. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-md-6 mb-4 mb-md-0">
              <p class="font-size-2 mt-2">© SriJay Software Solutions Pvt Ltd. All rights reserved.</p>
              
            </div>
            
          </div>
    </div>
</footer>
