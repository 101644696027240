import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators,ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [ReactiveFormsModule], 
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent {
  contactForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      msg_subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.contactForm.valid) {
      const mailtoLink = this.generateMailtoLink();
      window.location.href = mailtoLink;
    }
  }

  generateMailtoLink(): string {
    const formValues = this.contactForm.value;
    const subject = encodeURIComponent(formValues.msg_subject);
    const body = encodeURIComponent(
      `Name: ${formValues.name}\nEmail: ${formValues.email}\nPhone Number: ${formValues.phone_number}\n\nMessage:\n${formValues.message}`
    );

    return `mailto:sriram@sjsolutions.in?subject=${subject}&body=${body}`;
  }
  navigateToLogin() {
    window.location.href = 'http://prod.workforce.sjapps.in/#/login';
  }
}
